
.ant-layout-sider {
  background: #fff !important;
  border-right: 0.5px solid #e8e8e8;
  overflow: auto;
  min-height: 100vh;
  height: 100%;
  position: fixed !important;
  padding-top: 10px;
}


.ant-layout-header {
  background-color: #ffffff !important;
  border-bottom: 0.5px solid #e8e8e8;
  z-index:1 !important;
}

.ant-menu-item-selected {
    background-color: rgba(10,34,64, 0.1) !important;
    color: @primary-color !important;
}

.toggle-bar {
    position: absolute;
    top: 20px;
    z-index: 999;
    padding: 0 10px
    
}

.ant-tooltip-inner {
  background-color: @lightBlue !important;
}