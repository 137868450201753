@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;600;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700;900&display=swap');

h1, h2, h3, h4, h5, h6, p ,a, label, li {
    font-family: 'Cairo', sans-serif;
    margin: 0 ;
    padding: 0 ;
  }
  
  .my-font{
    font-family: 'Cairo', sans-serif !important;
  }
  
  .h1, .h2, .h3, .h4, .h5, .h6, .p, .a, .btn{
    font-family: 'Cairo', sans-serif;
   
  }
  
  h1{
    font-family: 'Cairo', sans-serif;
    font-weight: 600;
  }
