.row-served {
  background-color: @darkGray;
  position: relative;
}
.row-served::after {
  position: absolute;
  left: 0;
  top: 48%;
  height: 4px;
  background: rgba(165, 96, 96, 0.4);
  content: "";
  width: 100%;
  display: block;
}
