.iframe-content {
  border: 20px solid #000000;
  width: 100%;
  height: 100%;

  border-bottom-right-radius: 2.8rem;
  border-top-right-radius: 2.8rem;
  border-top-left-radius: 2.8rem;
  border-bottom-left-radius: 2.8rem;
}
.category-overflow::-webkit-scrollbar {
  width: 1px;
  height: 3px;
}
.category-overflow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.category-overflow::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0.5px solid slategrey;
}
// .view-food-table  thead[class*="ant-table-thead"] th{
//   background-color: green !important;
// }



