.rtl {
  direction: rtl;
  // font-family: Tahoma, Geneva, sans-serif;
}

.px-1 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}
.px-3 {
  padding-right: 13px !important;
  padding-left: 13px !important;
}
.px-4 {
  padding-right: 18px !important;
  padding-left: 18px !important;
}
.px-5 {
  padding-right: 23px !important;
  padding-left: 23px !important;
}
.py-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.py-3 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.py-4 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.py-5 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pb-3 {
  padding-bottom: 13px !important;
}
.pb-4 {
  padding-bottom: 18px !important;
}
.pb-5 {
  padding-bottom: 23px !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-3 {
  padding-top: 13px !important;
}
.pt-4 {
  padding-top: 18px !important;
}
.pt-5 {
  padding-top: 23px !important;
}
// margins;
.mx-1 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}
.mx-3 {
  margin-right: 13px !important;
  margin-left: 13px !important;
}
.mx-4 {
  margin-right: 18px !important;
  margin-left: 18px !important;
}
.mx-5 {
  margin-right: 23px !important;
  margin-left: 23px !important;
}
.my-0{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.my-1 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.my-3 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}
.my-4 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}
.my-5 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}
.mt-1{
  margin-top: 5px !important;
}
.mt-2{
  margin-top: 8px !important;
}
.mt-3 {
  margin-top: 13px !important;

}
.mt-4 {
  margin-top: 18px !important;
  
}
.mt-5 {
  margin-top: 23px !important;
}
.mb-1{
  margin-bottom: 5px !important;
}
.mb-2{
  margin-bottom: 8px !important;
}
.mb-3 {
  margin-bottom: 13px !important;

}
.mb-4 {
  margin-bottom: 18px !important;
  
}
.mb-5 {
  margin-bottom: 23px !important;
}
.border-5 {
  border-radius: 5px !important;
}
.border-8 {
  border-radius: 8px !important;
}
.border-12 {
  border-radius: 12px !important;
}
.border-15 {
  border-radius: 15px !important;
}

.pointer {
  cursor: pointer;
}
.light-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@mediumGrey: #fafafa;
@gray: #EFF2F5;
@lightBlue: #afe2e3;
@primary: #0a2240;
@darkGray: #dddddd;
@white: #fff;
@black: #000;
@import (inline) "./Style/google-fonts.css";
@import "./Style/sidebar.less";
@import "./Style/add-restaurant.less";
@import "./Style/tables.less";
@import "./Style/order-card.less";
@import "./Style/orders.less";
@import "./Style/design.less";
@import "./Style/waiting-list.less";
@import "./Style/foodics-new.less";
@import "./Style/home.less";
// @import "./Components/CustomButton/customBtn.less";
@import "./Style/auth.less";
@import "~antd/dist/antd.less";

@primary-color: #0a2240;@error-color: #FC4C3A;