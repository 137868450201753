.tables-card-container p {
    margin: 0;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-content {
    // height: 120px;
    margin-top: -16px;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 16px;
    background: #fff;
    border-radius: 5px;
  }
  .tables-card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .tables-card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .tables-card-container > .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .tables-card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
  }
  [data-theme='compact'] .tables-card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
  [data-theme='dark'] .tables-card-container > .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  [data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
  }
  [data-theme='dark'] .tables-card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
  }
  [data-theme='dark'] .tables-card-container > .ant-tabs-card .ant-tabs-tab-active {
    background: #141414;
    border-color: #141414;
  }