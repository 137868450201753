.authLeftSide {
    background-color: @primary-color;
    min-height: 100vh;
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
}

@media only screen and (max-width: 580px) {
    .authLeftSide {
      display: none !important;
    }
  }

.authRightSide {
    min-height: 100vh;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.changeLanguage {
    position: absolute;
    top: 0;
    
    margin-top: 20px;
}

// .btnRegister {
//     background-color: ;
//     width: 50%;
//     color: @white;
// }
.logo-Login {
    margin-bottom: 40px;
}

.input {
    border-radius: 50px;
    background-color: @mediumGrey;
}

.sort-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
